/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {



        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages





                // Navigation
                var header      = $('#header');
                var navMenu     = $('.main__nav');
                var navOpen     = $('.nav__open');
                var navClose    = $('.nav__close');

                $('body').focusin(function(e) {
                    if (!$.contains($(navMenu)[0], document.activeElement)) {
                        navClose.trigger('click');
                    }
                });
                //keyboard navigation
                navMenu.on('keydown', function(event){
                    // keyCode -> http://www.javascripter.net/faq/keycodes.htm
                    // 27 = esc
                    if  (event.keyCode === 27) {
                        navClose.trigger('click');
                    }
                });
                // open navigation
                navOpen.click(
                    function(){
                        navOpen.attr('aria-expanded', navOpen.attr( 'aria-expanded' ) === 'false' ? 'true' : 'false' );
                        navClose.attr('aria-expanded', navClose.attr( 'aria-expanded' ) === 'false' ? 'true' : 'false' );
                        navMenu.addClass('open');
                        header.addClass('open');
                        $('html, body').addClass('main__nav-opened');
                    }
                );
                // close navigation
                navClose.click(
                    function(){
                        navOpen.attr('aria-expanded', navOpen.attr( 'aria-expanded' ) === 'false' ? 'true' : 'false' );
                        navClose.attr('aria-expanded', navClose.attr( 'aria-expanded' ) === 'false' ? 'true' : 'false' );
                        navMenu.removeClass('open');
                        header.removeClass('open');
                        $('html, body').removeClass('main__nav-opened');
                    }
                );


                // Header after scroll
                var headerScrollAnchor = $('#main').first().offset().top + 80;
                // var header = $('#header');

                var headerFixed = function(){
                    var wst = $(window).scrollTop();
                    if( wst >= headerScrollAnchor ){
                        header.addClass('after-scroll');
                    } else {
                        header.removeClass('after-scroll');
                    }
                };
                headerFixed();

                $(window).on('scroll', function() {
                    headerFixed();
                });




                // Magnific Popup
                
                // Image
                var magPopImg = $('.magnificpopup');
                magPopImg.magnificPopup({
                    type: 'image',
                    image: {
                        titleSrc:
                        function(item) {
                            return item.el.find('img').attr('alt');
                        }
                    }
                });





            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },





        // Pianta
        'page_template_pianta': {
            init: function() {
                // JavaScript to be fired on the home page



                // Questo codice si esegue quando il DOM è completamente caricato
                $(document).ready(function() {
                    // Seleziona il campo specifico tramite il suo ID
                    var specificField = $('#acf-field_6705399931a4a'); // Seleziona il campo con ID specifico

                    // Verifica se il campo esiste
                    if (specificField.length) {
                        // Crea un nuovo elemento HTML (immagine di anteprima)
                        var nuovoElemento = $('<img class="img img--preview" id="imagePreview" src="" style="display:none;" />');

                        // Inserisci l'elemento subito dopo il campo con ID specifico
                        specificField.before(nuovoElemento);
                    }
                });

                // Gestione dell'evento di cambio immagine nel campo specifico
                $(document).on('change', '#acf-field_6705399931a4a', function() {
                    var input = $(this)[0];  // Seleziona l'input di tipo file

                    // Verifica se è stato selezionato un file
                    if (input.files && input.files[0]) {
                        var reader = new FileReader();

                        // Quando il file è stato caricato
                        reader.onload = function(e) {
                            // Imposta l'URL dell'immagine nell'anteprima e mostrala
                            $('#imagePreview').attr('src', e.target.result).show();
                        };

                        // Leggi il file come Data URL
                        reader.readAsDataURL(input.files[0]);
                    }
                });

            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS

            }
        },





    };// Sage

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };// UTIL

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
